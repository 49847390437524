
import { defineComponent, reactive, watch } from 'vue';
import Container from '@/components/Container.vue';
import ContainerHeader from '@/components/ContainerHeader.vue';
import ModalMarketNew from '@/views/markets-and-malls/components/ModalMarketNew.vue';
import useFetch from '@/modules/useFetch';
import api from '@/api';
import ViewportObserver from '@/components/ViewportObserver.vue';
import useUgns, { UgnsOption } from '@/modules/useUgns';
import { MarketsRow } from '@/types/Markets';

interface MarketsFilters extends Record<string, unknown>{
  name: string,
  ugnsId: UgnsOption,
}

export default defineComponent({
  name: 'MarketsAndMallsMarketIndex',
  components: {
    ViewportObserver,
    ModalMarketNew,
    ContainerHeader,
    Container,
  },
  setup() {
    const modalMarket = reactive({
      display: false,
    });
    const { ugns: ugnsSelector } = useUgns();
    ugnsSelector.fetch();

    const markets = useFetch({
      rows: [] as Record<string, unknown>[],
      filters: {
        ugnsId: ugnsSelector.selected,
        name: '',
      } as MarketsFilters,
      immediateFilters: ['ugnsId'],
      delayedFilters: ['name'],
      async fetchContents() {
        const { ugnsId: ugns, name } = markets.filters as MarketsFilters;
        const ugnsId = ugns?.id || 0;
        const params = {
          ...name && { name },
          ...ugnsId && { ugnsId },
          web: true,
        };
        return (await api.markets.findAll({ ...params })).data;
      },
    });

    markets.fetch();

    const cols = [
      {
        label: 'УГНС',
        display: (row: MarketsRow) => row.ugnsTnoCode,
        style: 'vertical-align:top',
      },
      {
        label: 'Рынки',
        component: (row: MarketsRow) => ({
          name: 'TOGroupedList',
          row: row.markets,
          field: 'name',
          tag: 'ol',
          link: 'markets-and-malls-markets-market-id',
          getId: (entry: MarketsRow['markets']) => ({ marketId: entry.id }),
        }),
      },
      {
        label: 'Кол-во подрынков',
        component: (row: MarketsRow) => ({
          name: 'TOGroupedList',
          row: row.markets,
          field: 'countMarketSub',
        }),
      }, {
        label: ' Кол-во НП',
        component: (row: MarketsRow) => ({
          name: 'TOGroupedList',
          row: row.markets,
          field: 'countNalogPayers',
        }),
      },
    ];

    watch(
      () => [modalMarket.display],
      () => {
        document.body.style.overflow = [modalMarket.display].includes(true) ? 'hidden' : 'initial';
      },
      { immediate: true },
    );

    return {
      cols,
      markets,
      modalMarket,
      ugnsSelector,
    };
  },
});
