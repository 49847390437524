
import {
  defineComponent, reactive, watch,
} from 'vue';
import Modal from '@/components/UI/Modal/index.vue';
import useFilePreview from '@/modules/useFilePreview';
import api from '@/api';

export default defineComponent({
  components: {
    Modal,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const filePreview = Object.assign(useFilePreview(), {
      display: false,
    });
    const newFile = reactive({
      loading: false,
      file: null as File | null,
      async submit() {
        newFile.loading = true;
        try {
          await api.markets.createByFile(newFile.file as File);
          alert('Загружено успешно');
          emit('close');
        } catch (e: unknown) {
          alert((e as Record<string, unknown>)?.message || 'Неизвестная ошибка на сервере');
        } finally {
          newFile.loading = false;
        }
      },
    });
    watch(
      () => [newFile.file],
      () => {
        if (!newFile.file) return;
        filePreview.decode(newFile.file);
      },
    );
    return {
      filePreview,
      newFile,
    };
  },
});
